import { presignedURL } from './types/upload.js'
import { state as initialState } from './states/upload.js'

const uploadReducer = (state = initialState, action) => {
  switch (action.type) {
    case presignedURL.REQUEST:
      return {
        ...state,
        createPresignedURL: {
          loading: true,
          response: null,
          errorMsg: ''
        }
      }
    case presignedURL.SUCCESS:
      return {
        ...state,
        createPresignedURL: {
          loading: false,
          response: action.data,
          errorMsg: ''
        }
      }
    case presignedURL.FAIL:
      return {
        ...state,
        createPresignedURL: {
          loading: false,
          response: null,
          errorMsg: 'Problemas creando la url, por favor intente más tarde'
        }
      }
    default:
      return state
  }
}

export default uploadReducer
