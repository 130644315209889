/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
import './src/styles/global.css'

// You can delete this file if you're not using it
import Provider from './src/reducers/provider'
export const wrapRootElement = Provider

export const onClientEntry = () => {
    if (typeof window !== 'undefined') {
        const script = document.createElement('script');
        script.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5952890777430378';
        script.async = true;
        script.crossOrigin = 'anonymous';
        document.head.appendChild(script);
    }
};
  