import { create, getById, get, getAndConcat, update } from './types/proposal.js'
import { state as initialState } from './states/proposal.js'

const proposalReducer = (state = initialState, action) => {
  switch (action.type) {
    case create.REQUEST:
      return {
        ...state,
        createProposal: {
          loading: true,
          response: null,
          errorMsg: ''
        }
      }
    case create.SUCCESS:
      return {
        ...state,
        createProposal: {
          loading: false,
          response: action.data,
          errorMsg: ''
        }
      }
    case create.FAIL:
      return {
        ...state,
        createProposal: {
          loading: false,
          response: null,
          errorMsg: 'Problemas al crear la propuesta, por favor intente más tarde'
        }
      }
    case update.REQUEST:
      return {
        ...state,
        updateProposal: {
          loading: true,
          response: null,
          errorMsg: ''
        }
      }
    case update.SUCCESS:
      return {
        ...state,
        updateProposal: {
          loading: false,
          response: action.data,
          errorMsg: ''
        }
      }
    case update.FAIL:
      return {
        ...state,
        updateProposal: {
          loading: false,
          response: null,
          errorMsg: 'Problemas al guardar el propuesta, por favor intente más tarde'
        }
      }
    case getById.REQUEST:
      return {
        ...state,
        getProposalById: {
          loading: true,
          response: null,
          errorMsg: ''
        }
      }
    case getById.SUCCESS:
      return {
        ...state,
        getProposalById: {
          loading: false,
          response: action.data,
          errorMsg: ''
        }
      }
    case getById.FAIL:
      return {
        ...state,
        getProposalById: {
          loading: false,
          response: null,
          errorMsg: 'Problemas al obtener la propuesta, por favor intente más tarde'
        }
      }
    case get.REQUEST:
      return {
        ...state,
        getProposals: {
          loading: true,
          response: null,
          errorMsg: ''
        }
      }
    case get.SUCCESS:
      return {
        ...state,
        getProposals: {
          loading: false,
          response: action.data,
          errorMsg: ''
        }
      }
    case get.FAIL:
      return {
        ...state,
        getProposals: {
          loading: false,
          response: null,
          errorMsg: 'Problemas al obtener las propuestas, por favor intente más tarde'
        }
      }
    case getAndConcat.REQUEST:
      return {
        ...state,
        getProposals: {
          ...state.getProposals,
          loading: true,
          errorMsg: ''
        }
      }
    case getAndConcat.SUCCESS:
      const data = {
        ...action.data,
        docs: [
          ...state.getProposals.response.docs,
          ...action.data.docs
        ]
      }
      return {
        ...state,
        getProposals: {
          loading: false,
          response: data,
          errorMsg: ''
        }
      }
    case getAndConcat.FAIL:
      return {
        ...state,
        getProposals: {
          ...state.getProposals,
          loading: false,
          errorMsg: 'Problemas al obtener las propuestas restantes, por favor intente más tarde'
        }
      }
    default:
      return state
  }
}

export default proposalReducer
