export const state = {
  createProject: {
    loading: false,
    response: null,
    errorMsg: ''
  },
  updateProject: {
    loading: false,
    response: null,
    errorMsg: ''
  },
  getProjectById: {
    loading: false,
    response: null,
    errorMsg: ''
  },
  getProjects: {
    loading: false,
    response: null,
    errorMsg: ''
  },
  acceptProposal: {
    loading: false,
    response: null,
    errorMsg: ''
  }

}
