import { register, login, update, getById, get, getAndConcat, logout } from './types/user.js'
import { state as initialState } from './states/user.js'

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case register.REQUEST:
      return {
        ...state,
        register: {
          loading: true,
          response: null,
          errorMsg: ''
        }
      }
    case register.SUCCESS:
      return {
        ...state,
        register: {
          loading: false,
          response: action.data,
          errorMsg: ''
        }
      }
    case register.FAIL:
      return {
        ...state,
        register: {
          loading: false,
          response: null,
          errorMsg: 'Problemas al registrarse, por favor intente más tarde'
        }
      }
    case register.CLEAN:
      return {
        ...state,
        register: {
          loading: false,
          response: null,
          errorMsg: ''
        }
      }
    case login.REQUEST:
      return {
        ...state,
        login: {
          loading: true,
          response: null,
          errorMsg: ''
        }
      }
    case login.SUCCESS:
      return {
        ...state,
        login: {
          loading: false,
          response: action.data,
          errorMsg: ''
        }
      }
    case login.FAIL:
      return {
        ...state,
        login: {
          loading: false,
          response: null,
          errorMsg: 'Usuario incorrecto'
        }
      }
    case update.REQUEST:
      return {
        ...state,
        updateUser: {
          loading: true,
          errorMsg: ''
        }
      }
    case update.SUCCESS:
      return {
        ...state,
        login: {
          ...state.login,
          response: action.data
        },
        updateUser: {
          loading: false,
          errorMsg: ''
        }
      }
    case update.FAIL:
      return {
        ...state,
        updateUser: {
          loading: false,
          errorMsg: 'No se pudo actualizar al usuario, por favor intente más tarde'
        }
      }
    case getById.REQUEST:
      return {
        ...state,
        getUserById: {
          loading: true,
          response: null,
          errorMsg: ''
        }
      }
    case getById.SUCCESS:
      return {
        ...state,
        getUserById: {
          loading: false,
          response: action.data,
          errorMsg: ''
        }
      }
    case getById.FAIL:
      return {
        ...state,
        getUserById: {
          loading: false,
          response: null,
          errorMsg: 'Problemas al obtener el proyecto, por favor intente más tarde'
        }
      }
    case get.REQUEST:
      return {
        ...state,
        getUsers: {
          loading: true,
          response: null,
          errorMsg: ''
        }
      }
    case get.SUCCESS:
      return {
        ...state,
        getUsers: {
          loading: false,
          response: action.data,
          errorMsg: ''
        }
      }
    case get.FAIL:
      return {
        ...state,
        getUsers: {
          loading: false,
          response: null,
          errorMsg: 'Problemas al obtener los proyectos, por favor intente más tarde'
        }
      }
    case getAndConcat.REQUEST:
      return {
        ...state,
        getUsers: {
          ...state.getUsers,
          loading: true,
          errorMsg: ''
        }
      }
    case getAndConcat.SUCCESS:
      const data = {
        ...action.data,
        docs: [
          ...state.getUsers.response.docs,
          ...action.data.docs
        ]
      }
      return data

    case logout.REQUEST:
      return {
        ...state,
        logout: {
          loading: true,
          errorMsg: ''
        }
      }
    case logout.SUCCESS:
      return {
        ...state,
        login: {
          ...state.login,
          response: null
        },
        logout: {
          loading: false,
          errorMsg: ''
        }
      }
    case logout.FAIL:
      return {
        ...state,
        logout: {
          loading: false,
          errorMsg: 'No se pudo cerrar la sesión'
        }
      }
    default:
      return state
  }
}

export default userReducer
