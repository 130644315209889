export const state = {
  createProposal: {
    loading: false,
    response: null,
    errorMsg: ''
  },
  updateProposal: {
    loading: false,
    response: null,
    errorMsg: ''
  },
  getProposalById: {
    loading: false,
    response: null,
    errorMsg: ''
  },
  getProposals: {
    loading: false,
    response: null,
    errorMsg: ''
  }

}
