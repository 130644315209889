export const create = {
  REQUEST: 'CREATE_PROPOSAL_REQUEST',
  SUCCESS: 'CREATE_PROPOSAL_SUCCESS',
  FAIL: 'CREATE_PROPOSAL_FAIL'
}

export const getById = {
  REQUEST: 'GET_PROPOSAL_BY_ID_REQUEST',
  SUCCESS: 'GET_PROPOSAL_BY_ID_SUCCESS',
  FAIL: 'GET_PROPOSAL_BY_ID_FAIL'
}

export const get = {
  REQUEST: 'GET_PROPOSALS_REQUEST',
  SUCCESS: 'GET_PROPOSALS_SUCCESS',
  FAIL: 'GET_PROPOSALS_FAIL'
}

export const getAndConcat = {
  REQUEST: 'GET_PROPOSALS_AND_CONCAT_REQUEST',
  SUCCESS: 'GET_PROPOSALS_AND_CONCAT_SUCCESS',
  FAIL: 'GET_PROPOSALS_AND_CONCAT_FAIL'
}

export const update = {
  REQUEST: 'UPDATE_PROPOSAL_REQUEST',
  SUCCESS: 'UPDATE_PROPOSAL_SUCCESS',
  FAIL: 'UPDATE_PROPOSAL_FAIL'
}
