import { get } from './types/category.js'
import { state as initialState } from './states/category.js'

const categoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case get.REQUEST:
      return {
        ...state,
        getCategories: {
          loading: true,
          response: null,
          errorMsg: ''
        }
      }
    case get.SUCCESS:
      return {
        ...state,
        getCategories: {
          loading: false,
          response: action.data,
          errorMsg: ''
        }
      }
    case get.FAIL:
      return {
        ...state,
        getCategories: {
          loading: false,
          response: null,
          errorMsg: 'Problemas al obtener los categorias, por favor intente más tarde'
        }
      }
    default:
      return state
  }
}

export default categoryReducer
